<template>
  <member-setting-container>
    <dashboard-layout
      :items="items"
      :english-video="englishVideo"
      :spanish-video="spanishVideo"
      :url="url"
      :steps-title="$t('myself.dashboard.configure-account')"
      :video-title="$t('myself.dashboard.video-title')"
      :video-subtitle="$t('myself.dashboard.video-subtitle')"
      :welcome-title="$t('myself.dashboard.welcome')"
      :link-video="linkVideo"
    />
  </member-setting-container>
</template>

<script>
import DashboardLayout from '@/views/myself/components/DashboardLayout.vue';
import MemberSettingContainer from '@/views/myself/components/MemberSettingContainer.vue';
//import ComingSoon from '@/assets/images/brand/Coming_soon .svg';

export default {
  components: {
    DashboardLayout,
    MemberSettingContainer,
  },
  computed: {
    linkVideo() {
      return 'https://www.youtube.com/embed/j5-AmZdbmL0';
    },
    items() {
      return [
        {
          title: this.$t('myself.steps.first.title'),
          rest: this.$t('myself.steps.first.rest', { item: `<a href='${this.url(this.$t('myself.steps.first.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('myself.steps.first.subtitle'))}</a>`}),
          completed: false,
        },
        {
          title: this.$t('myself.steps.second.title'),
          rest: this.$t('myself.steps.second.rest', { item: `<a href='${this.url(this.$t('myself.steps.second.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('myself.steps.second.subtitle'))}</a>`}),
          completed: false,
        },
        {
          title: this.$t('myself.steps.third.title'),
          rest: this.$t('myself.steps.third.rest', { item: `<a href='${this.url(this.$t('myself.steps.third.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('myself.steps.third.subtitle'))}</a>`}),
          completed: false,
        },
        {
          title: this.$t('myself.steps.fourth.title'),
          rest: this.$t('myself.steps.fourth.rest', { item: `<a href='${this.url(this.$t('myself.steps.fourth.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('myself.steps.fourth.subtitle'))}</a>`}),
          completed: false,
        },
        {
          title: this.$t('myself.steps.fifth.title'),
          rest: this.$t('myself.steps.fifth.rest', { item: `<a href='${this.url(this.$t('myself.steps.fifth.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('myself.steps.fifth.subtitle'))}</a>`}),
          completed: false,
        },
        {
          title: this.$t('myself.steps.sixth.title'),
          rest: this.$t('myself.steps.sixth.rest', { item: `<a href='${this.url(this.$t('myself.steps.sixth.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('myself.steps.sixth.subtitle'))}</a>`}),
          completed: false,
        },
      ];
    },
    englishVideo() {
      return 'https://www.youtube.com/embed/j5-AmZdbmL0';
    },
    spanishVideo() {
      return 'https://www.youtube.com/embed/j5-AmZdbmL0';
    },
    community() {
      return this.$store.getters.currentCollective;
    },
  },
  methods: {
    getItem(item) {
      const step = item;
      return step;
    },
    url(item) {
      let section = '';
      if (item === this.$t('myself.steps.first.subtitle')) {
        section = 'settings';
      }
      if (item === this.$t('myself.steps.second.subtitle')) {
        section = 'notifications';
      }
      if (item === this.$t('myself.steps.third.subtitle')) {
        section = 'connections';
      }
      if (item === this.$t('myself.steps.fourth.subtitle')) {
        section = 'calendar';
      }
      if (item === this.$t('myself.steps.fifth.subtitle')) {
        section = 'payments';
      }
      if (item === this.$t('myself.steps.sixth.subtitle')) {
        section = 'referrals';
      }
      console.log(section)

      return `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', this.community.slug).replace(
        '{subdomain}',
        'app',
      )}/myself/${section}`;
    },
  },
};
</script>
